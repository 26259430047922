@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

html,
body {
    height: 100%;
    box-sizing: border-box;
    background-color: whitesmoke;
}

html {
    font-size: 16px;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 18px;

}

a {
    text-decoration: none;
}